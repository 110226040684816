/* You can add global styles to this file, and also import other style files */

/*@import "@angular/material/prebuilt-themes/indigo-pink.css";*/

//@import 'Styles/Icons.scss';

@import 'Styles/Helpers.scss';
@import 'Styles/Variables.scss';
@import 'Styles/App-theme.scss';
@import 'Styles/App.scss';
@import 'Styles/Tabs.scss';
@import 'Styles/PageHeader.scss';
@import 'Styles/Dialogs.scss';
@import 'Styles/Toast.scss';
@import 'Styles/Map.scss';
@import 'Styles/PhoneView.scss';

@import 'Styles/iq-virtual-scroll.scss';
@import 'Styles/MaterialStyleOverrides.scss';
@import 'Styles/typography/typography-classes.scss';
@import 'Styles/typography/material-overrides/_tab-typography.scss';

//Needs to be last because we are overriding all other styles in this file.  Even if we have already set them in another file
@import 'app/Pages/Tickets/Details/_TicketDetails-material-overrides.component.scss';

@import "Styles/Prism.scss";

@import "app/Shared/Components/GridView/GridView-GlobalStyles.scss";
