@use '@angular/material' as mat;

@mixin actionable-items-theme($theme) {
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);

    @include mat.button-theme($theme);
    @include mat.fab-theme($theme);
    @include mat.icon-button-theme($theme);
    @include mat.button-toggle-theme($theme);
    @include mat.checkbox-theme($theme);
    @include mat.chips-theme($theme);
    @include mat.radio-theme($theme);
    @include mat.slide-toggle-theme($theme);
    @include mat.slider-theme($theme);

    .ngx-pagination .current {
        background-color: mat.get-color-from-palette($primary);
        color: mat.get-color-from-palette($primary, "default-contrast");
    }

    .actionable-background {
        background-color: mat.get-color-from-palette($primary);
        color: mat.get-color-from-palette($primary, "default-contrast");

        .gray-primary-text, .link {
            color: mat.get-color-from-palette($primary, "default-contrast");
        }
    }

    .lbp-background, .extcircumstances-background {
        &:not(.verified) {
            background-color: mat.get-color-from-palette($primary);

            &, .gray-primary-text, .link {
                color: mat.get-color-from-palette($primary, "default-contrast");
            }
        }

        border: solid 1px mat.get-color-from-palette($primary);
    }

    .landlord-background {
        &:not(.verified) {
            background-color: mat.get-color-from-palette($accent);

            &, .gray-primary-text, .link {
                color: mat.get-color-from-palette($accent, "default-contrast");
            }
        }

        border: solid 1px mat.get-color-from-palette($accent);
    }
    //The material stuff doesn't use the contrast color for text colors (they use black).  So override that to always use the contrast
    //.mat-toolbar.mat-primary,
    .mat-mdc-raised-button.mat-primary, .mat-mdc-fab.mat-primary, .mat-mdc-mini-fab.mat-primary {
        color: mat.get-color-from-palette($primary, "default-contrast") !important;
    }

    .mat-button-toggle-group {
        .mat-button-toggle:not(.mat-button-toggle-checked)  {
            //  Must also specify the width and type because angular has a "border-left: 1px solid" defined!
            border: 1.5px solid mat.get-color-from-palette($primary, "darker") !important;
        }
        .mat-button-toggle-checked {
            border: 1.5px solid mat.get-color-from-palette($primary) !important;
            background-color: mat.get-color-from-palette($primary);
            .mat-button-toggle-label-content {
                color: mat.get-color-from-palette($primary, "default-contrast") !important;
            }
        }
    }

    //  Angular 15 defaults to almost completely hiding the line under a disabled mat-form-field.  This gives
    //  it a little more color so the user can actually see where they are...
    .mdc-text-field--disabled .mdc-line-ripple::before {
        border-bottom-color: lightgray !important;
    }

    //  Angular 15 default styles for sliders is UGLY
    .mat-mdc-slide-toggle .mdc-switch:enabled .mdc-switch__track::after {
        //  This is the horizontal bar
        //  !important needed here so that it also overrides the color when hovering (which is dark brown for some unknown reason!)
        background: mat.get-color-from-palette($primary, "lighter") !important;
    }
    .mat-mdc-slide-toggle .mdc-switch.mdc-switch--selected:enabled .mdc-switch__handle::after,
    .mat-mdc-slide-toggle .mdc-switch.mdc-switch--selected:disabled .mdc-switch__handle::after{
        //  This is the checked circle.
        //  !important makes it also affect hovering
        background: mat.get-color-from-palette($primary) !important;
    }
    .mat-mdc-slide-toggle .mdc-switch.mdc-switch--unselected:enabled .mdc-switch__handle::after {
        //  This is the un-checked circle
        //  Could also set this to white.  Then all unselected will show as white but when you hover, it
        //  will change to the accent color (due to the next style)
        background: mat.get-color-from-palette($accent) !important;
    }
    .mat-mdc-slide-toggle .mdc-switch.mdc-switch--unselected:enabled:hover .mdc-switch__handle::after {
        //  This is the un-checked circle when hovering
        background: mat.get-color-from-palette($accent) !important;
    }
    .mat-mdc-slide-toggle .mdc-switch.mdc-switch--unselected:disabled .mdc-switch__handle::after {
        //  Disabled and unchecked shows as white background with very light gray border
        box-shadow: 0 2px 1px -1px #0003, 0 1px 1px #00000024, 0 1px 3px #0000001f;
        background-color: #fafafa;
    }
}
