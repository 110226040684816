@use '@angular/material' as mat;

@mixin tab-theme($theme) {
    // Extract whichever individual palettes you need from the theme.
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $activeBackgroundColor: mat.get-color-from-palette($primary, 50); // white
    .mat-mdc-tab-group.iq-tab-style {
        .mat-mdc-tab-header {
            border-bottom: 1px solid rgba(0,0,0,.12) !important;       //  Restore the bottom border that was removed in Angular 15
        }

        .mat-tab-body-wrapper, .mat-mdc-tab-body-wrapper {
            background-color: $activeBackgroundColor;
        }
        .mat-mdc-tab-label-container {
            .mat-mdc-tab-list {
                .mat-mdc-tab-labels {
                    //  Default text color is black
                    .mat-mdc-tab:not(.mdc-tab--active) {
                        background-color: mat.get-color-from-palette($primary, 800); //  light gray
                    }
                    //Make the background of the active tab white
                    .mat-mdc-tab.mdc-tab--active {
                        background-color: $activeBackgroundColor;
                    }
                }
            }
        }
    }
    //  Tabs that fall inside another set of Tabs.  Override styles set above to make them look a little different.
    //  Because these tabs are inside other tabs, they are ALWAYS going to pick up those default styles.
    //  (tried preventing that with :not(.iq-child-tabs) but that didn't work).
    //  So must override or unset everything above or it will be inherited here.
    .mat-mdc-tab-group.iq-tab-style.iq-child-tabs {
        .mat-mdc-tab-label-container {
            .mat-mdc-tab-list {
                .mdc-tab-indicator__content--underline {
                    border-color: mat.get-color-from-palette($accent);
                }
                .mat-mdc-tab-labels {
                    .mat-mdc-tab:not(.mdc-tab--active) {
                        background-color: $activeBackgroundColor;
                    }
                    .mat-mdc-tab.mdc-tab--active {
                        color: mat.get-color-from-palette($primary);
                    }
                }
            }
        }
    }
}
