@use '@angular/material' as mat;

@mixin ng-select-typography($typography) {
    .ng-select .ng-select-container .ng-value-container {
        .ng-input>input {
            text-transform: uppercase;

            font-size: mat.font-size($typography, body-1);
            font-weight: mat.font-weight($typography, body-1);
            line-height: mat.line-height($typography, body-1);
        }

        .ng-placeholder {
            font-style: italic;
        }
    }
}
