@use '@angular/material' as mat;

@mixin dialog-theme($theme) {
    // Extract whichever individual palettes you need from the theme.
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);

    .mat-mdc-dialog-title {
        color: mat.get-color-from-palette($primary);
        border-bottom: 1px solid mat.get-color-from-palette($primary);
    }

    .mat-mdc-dialog-actions {
        border-top: 1px solid mat.get-color-from-palette($primary);
    }
}
