//  Global styles used by iq-entry-form - including ticket entry and project entry

@use '@angular/material' as mat;

//  This $highlight-color is the $accent/lighter color from the green them (which is yellow but slightly softer then the standard yellow).
//  It is hardcoded to make it consistent in all the themes (and because the irth theme would result in showing as dark blue).
$highlight-color: #F3FF62;

@mixin entry-form-theme($theme, $grayFontTheme) {
    // Extract whichever individual palettes you need from the theme.
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $warn: map-get($theme, warn);

    $grayFontPrimary: map-get($grayFontTheme, primary);
    $grayFontAccent: map-get($grayFontTheme, accent);

    //  TODO: Replace "iq-ticket-entry..." class names with just "iq-entry-form...".
    //  For now, both style names are handled here so we don't break everything in ticket entry.

    .iq-ticket-entry-label, .iq-entry-form-label {
        color: mat.get-color-from-palette($grayFontPrimary);
    }

    .iq-ticket-entry-section, .iq-entry-form-section {

        .iq-section-item-view, .iq-section-item-edit {
            .view, .edit {
                //  Need to exclude the floating mat-label for a MatFormField
                label:not(.mat-mdc-floating-label), .label {
                    color: mat.get-color-from-palette($grayFontPrimary);
                }
            }
        }

        .iq-section-group {
            //  Need to exclude the floating mat-label for a MatFormField
            div.label:not(.mat-mdc-floating-label) {
                color: mat.get-color-from-palette($grayFontPrimary);
            }
        }

        .mat-mdc-form-field.mat-focused {
            .mat-mdc-form-field-flex {
                //  Not using the $accent/lighter color from the theme because then the "irth" theme shows in the background as a dark blue.
                //  This $highlight-color is the $accent/lighter color from the green them (which is yellow but slightly softer then the standard yellow).
                //background-color: mat.get-color-from-palette($accent, lighter);
                .iq-entry-form-field-content, input, mat-select, textarea {
                    background-color: $highlight-color;
                }
            }
        }
        .mat-mdc-form-field.mat-form-field-invalid {
            .mat-mdc-text-field-wrapper.mdc-text-field--invalid {
                .mat-mdc-form-field-flex {
                    .iq-entry-form-field-content {
                        background-color: #ffcdd2;
                    }
                }
            }
        }

        .hide-when-disabled.mat-mdc-input-element:disabled,
        .hide-when-disabled.mat-mdc-select-disabled .mat-mdc-select-value {
            //  Setting the color of a disabled input is having no effect what-so-ever as of Angular 15!
            //  The only way to get these values to be hidden is to set the opacity to 0.  Which also has the side effect of hiding the background.
            //color: #F2F2F2 !important;
            opacity: 0 !important;
        }

        .ng-placeholder {
            //  Hide ng-select's default placeholder.  It overlaps with the standard Material placeholder (which is enabled by MtxSelect).
            //  Leaving it here shows the placeholder in gray as it animates from floating to not floating.
            display: none;
        }
    }
}
