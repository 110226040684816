//  This is included in the main styles.scss so that we can apply css styles to the nGrid components.


//  Moves the paginator to be displayed above the grid and to the right side of the page.
pbl-ngrid-paginator {
    position: absolute;
    right: 0;
    background-color:transparent !important;
}

//  Causes long lines to wrap for up to 3 lines.
//  Does cause the width calculations in the grid to not expand the columns as much as they probably could
//  because it does not know about the wrapping.
.pbl-ngrid-cell-ellipsis-3-lines .pbl-ngrid-cell, .pbl-ngrid-header-cell-ellipsis-3-lines .pbl-ngrid-header-cell {
    div {
        //  https://css-tricks.com/almanac/properties/l/line-clamp/
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }
}


//  The @pebula/ngrid package has not been upgraded to support Angular 15 yet so it's paginator is using the legacy css classes.
//  Until that gets upgraded, we need to import the Legacy Paginator and initialize the legacy styles in App-theme.scss.
//  We don't use the MatPaginator at all so this does not affect anything in Exactix.
//  These styles are also required to fix the buttons & select used for the page navigation (which we cannot switch to legacy).
button.mat-paginator-navigation-previous, button.mat-paginator-navigation-next {
    border: none;
    padding: initial;
    margin: initial;
    width: 40px;
    height: 40px;
    background: transparent;
}
.iq-typography-default .mat-paginator, .iq-typography-default .mat-paginator-page-size .mat-mdc-select-trigger {
    font-size: 12px;
    .mat-mdc-select-value {
        font-size: 12px;
    }
}
.iq-typography-small .mat-paginator, .iq-typography-small .mat-paginator-page-size .mat-mdc-select-trigger {
    font-size: 10px;
    .mat-mdc-select-value {
        font-size: 10px;
    }
}
.iq-typography-large .mat-paginator, .iq-typography-large .mat-paginator-page-size .mat-mdc-select-trigger {
    font-size: 14px;
    .mat-mdc-select-value {
        font-size: 14px;
    }
}
.mat-paginator-range-actions .mat-paginator-page-size-select.mat-form-field-appearance-fill {
    //  Must increase this because it's using the new styles for the select (which set width to 64px) and includes
    //  a ton of padding and the selected checkmark (which the 64px does not account for)!
    //  Don't have a way to make the overlay autosize for just this paginator because it's in an overlay and there
    //  isn't anything specific to the paginator that we can select on.
    width: 100px;
}
