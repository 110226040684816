@use '@angular/material' as mat;

@mixin ng-select-theme($theme, $grayFontTheme) {
    // Extract whichever individual palettes you need from the theme.
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $warn: map-get($theme, warn);

    $grayFontPrimary: map-get($grayFontTheme, primary);
    $grayFontAccent: map-get($grayFontTheme, accent);

    $primaryColor: mat.get-color-from-palette($primary);

    .ng-select {
        &.ng-select-focused {
            .ng-select-container {
                &:after {
                    border-color: $primaryColor;
                }

                &.ng-appearance-outline {
                    &:after,
                    &:hover:after {
                        border-color: $primaryColor;
                    }
                }

                .ng-value-container .ng-placeholder {
                    //color: $primaryColor;
                    color: mat.get-color-from-palette($grayFontPrimary);
                }

                .ng-arrow-wrapper .ng-arrow {
                    color: $primaryColor;
                }
            }
        }

        &.ng-select-disabled {
            .ng-value {
                width: 100%;
                background-color: #F2F2F2 !important;
                z-index: 2;     //  Needed or the input sits on top of the value and you can't see it!

                .ng-value-label {
                    color: rgba(0, 0, 0, 0.38);
                }
            }
        }
    }

    .ng-dropdown-panel .ng-dropdown-panel-items {
        max-height: 20em; //  default is 256px which makes it scroll most of the time.  There is no setting for "bottom of view"...
        .ng-option {
            font-size: 14px !important; //Need to be important so it will work for mat-selects too because they change this property
            height: auto !important; //Need to be important so it will work for mat-selects too because they change this property
            line-height: unset !important; //Need to be important so it will work for mat-selects too because they change this property
            min-height: unset !important;
            padding: 5px 16px;

            .highlighted {
                text-decoration: unset;
                background-color: yellow;
            }

            &.ng-option-selected {
                color: $primaryColor;
            }
        }

        .ng-optgroup {
            &.ng-option-selected {
                color: $primaryColor;
            }
        }
    }
}
