@use '@angular/material' as mat;

@mixin autocomplete-option-action($theme) {
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);

    .mat-mdc-option {
        .action {
            color: mat.get-color-from-palette($primary);
            border-bottom: 2px solid mat.get-color-from-palette($primary, default, .1); // $grayBorderColor;
        }
    }
    /*.mat-option.mat-active {
        background-color: rgba(0, 0, 0, 0.08); //  default has ".04" which is way too light
    }*/
}
